import React from 'react';
import './AboutUs.css'

function Home() {
  return (

 <div className='about'>
  


 </div>


  )
}

export default Home;