import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import './Quote.css';

function Quote() {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    address: '',
    services: [],
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData((prevData) => ({
        ...prevData,
        services: checked
          ? [...prevData.services, value]
          : prevData.services.filter((service) => service !== value),
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Debugging: Log the full formData to ensure it's correct
    console.log('Form Data Submitted:', formData);

    const templateParams = {
      name: formData.name,
      phone: formData.phone,
      email: formData.email,
      address: formData.address,
      services: formData.services.join(', '), // Format services into a string
    };

    emailjs
      .send(
        'service_vofuawt', // Replace with your EmailJS Service ID
        'template_xmq8lt1', // Replace with your EmailJS Template ID
        templateParams,
        '_p6CbwT9RLZpyI3sq' // Replace with your EmailJS Public Key
      )
      .then(
        (result) => {
          alert('Quote request submitted successfully!');
          console.log('EmailJS Success:', result.text);
        },
        (error) => {
          alert('Failed to submit quote request. Please try again.');
          console.error('EmailJS Error:', error.text);
        }
      );
  };

  return (
    <div className="quote-page">
      <h1 className="quote-title">Get Your Free Quote</h1>
      <form className="quote-form" onSubmit={handleSubmit}>
        {/* Contact Information */}
        <div className="contact-section">
          <div className="form-group">
            <label htmlFor="name">Full Name</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Enter your full name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone Number</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Enter your phone number"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email address"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="address">Address</label>
            <input
              type="text"
              id="address"
              name="address"
              placeholder="Enter your address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Service Selection */}
        <div className="services-section">
          <h2>Select the Services You Need</h2>
          <div className="services-grid">
            {[
              'Exterior Window Cleaning',
              'Interior Window Cleaning',
              'Pressure Washing',
              'Snow Removal',
              'Christmas Light Installation',
              'Commercial Window Cleaning',
              'Gutter Cleaning',
            ].map((service) => (
              <label className="service-item" key={service}>
                <input
                  type="checkbox"
                  name="services"
                  value={service}
                  onChange={handleChange}
                />
                {service}
              </label>
            ))}
          </div>
        </div>

        {/* Submit Button */}
        <div className="submit-section">
          <button type="submit" className="quote-button">
            Get My Quote
          </button>
        </div>
      </form>
    </div>
  );
}

export default Quote;
