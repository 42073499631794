import React from 'react';
import './ChooseUs.css';

function ChooseUs() {
  return (
    <div className='chooseus-container'>
      <h2 className='title'> Why choose us ?</h2>
      <div className='reasons'>
        <div className='reason'>
          <h3>Professional Team</h3>
          <p>Our team consists of experienced and professional cleaners dedicated to providing the best service.</p>
        </div>

        <div className='reason'>
          <h3>Eco-Friendly Products</h3>
          <p>We use eco-friendly products that are safe for your family and the environment.</p>
        </div>

        <div className='reason'>
          <h3>Satisfaction Guaranteed</h3>
          <p>We guarantee your satisfaction with our services. If you're not happy, we'll make it right.</p>
        </div>

        <div className='reason'>
          <h3>Affordable Prices</h3>
          <p>Our services are offered at competitive prices to ensure great value for your money.</p>
        </div>
      </div>
    </div>
    
  );
}

export default ChooseUs;