import React from 'react';
import './FAQ.css';

import Rescleaning from '../assests/Commericalwindow.jpeg';
import lighting from '../assests/getup.jpg'
import Snow from '../assests/snow-plowing-services-1024x652.jpg';
import powerwash from '../assests/never.jpg';



function FAQ() {
  return (
    <div className="what-we-do-container">
      <h2 className="section-title">What We Do</h2>
      <div className="services-grid">
        {/* Window Cleaning */}
        <div className="service-card">
          <img src={Rescleaning} alt="Window Cleaning" className="service-image" />
          <h3> Residential Window Cleaning</h3>
          <p>Your home or business will shine with our thorough and professional window cleaning services.</p>
          <a href="/services/window-cleaning" className="learn-more-button">Learn More</a>
        </div>
        {/* Snow Removal */}
        <div className="service-card">
          <img src={Snow} alt="Snow Removal" className="service-image" />
          <h3>Snow Removal</h3>
          <p>We provide fast and reliable snow removal to keep your property safe and accessible all winter long.</p>
          <a href="/services/snow-removal" className="learn-more-button">Learn More</a>
        </div>
        {/* Christmas Light Installation */}
        <div className="service-card">
          <img src={lighting} alt="Christmas Light Installation" className="service-image" />
          <h3>Christmas Light Installation</h3>
          <p>Make your holidays magical with professional Christmas light installation customized to your home or business.</p>
          <a href="/services/christmas-light-installation" className="learn-more-button">Learn More</a>
        </div>
        {/* Pressure Washing */}
        <div className="service-card">
          <img src={powerwash} alt="Pressure Washing" className="service-image" />
          <h3>Pressure Washing</h3>
          <p>Our high-pressure cleaning removes dirt, mold, and stains, making your surfaces look brand new.</p>
          <a href="/services/pressure-washing" className="learn-more-button">Learn More</a>
        </div>
        {/* Commercial Window Cleaning */}
        <div className="service-card">
          <img src={Rescleaning} alt="Commercial Window Cleaning" className="service-image" />
          <h3>Commercial Window Cleaning</h3>
          <p>We help businesses maintain a professional appearance with streak-free, crystal-clear windows.</p>
          <a href="/services/commercial-window-cleaning" className="learn-more-button">Learn More</a>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
