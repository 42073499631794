// pages/AboutUs.js
import React from 'react';
import './AboutUs.css'

function AboutUs() {
  return (

 <div className='about'>
  


 </div>


  )
}

export default AboutUs;
