import React from 'react';
import './ContactUs.css';

function ContactUs() {
  return (
    <div className='contact-page'>
      <div className='contact-container'>
        <h1>Contact Us</h1>
        <form className='contact-form'>
          <div className='form-group'>
            <label htmlFor='name'>Name</label>
            <input type='text' id='name' name='name' required />
          </div>
          <div className='form-group'>
            <label htmlFor='email'>Email</label>
            <input type='email' id='email' name='email' required />
          </div>
          <div className='form-group'>
            <label htmlFor='message'>Message</label>
            <textarea id='message' name='message' rows='5' required></textarea>
          </div>
          <button type='submit' className='submit-btn'>Submit</button>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
