import React, { useState } from 'react';
import './navbar.css';
import Image from '../assests/Website-Favicon-Color.png';
import { Link, useLocation } from 'react-router-dom';
import { CiMenuBurger } from "react-icons/ci";

function Navbar() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  return (
    <nav className='navbar'>
      <div className='containers'>
        <Link to="/">
          <img className='hello' src={Image} alt="logo" />
        </Link>
        <ul className={mobileMenu ? 'show-mobile-menu' : 'hide-mobile-menu'}>
          <li className={location.pathname === '/' ? 'active' : ''}>
            <Link to="/">Home</Link>
          </li>
          <li className='dropdown'>
            <Link to="/services" className={location.pathname === '/services' ? 'active' : ''}>
              Services
            </Link>
            <span className="arrow-down"></span> {/* Arrow next to Services */}
            
            <ul className='dropdown-content'>
              <li><Link to="/services/residential-window-cleaning">Residential Window Cleaning</Link></li>
              <li><Link to="/services/commercial-window-cleaning">Commercial Window Cleaning</Link></li>
              <li><Link to="/services/pressure-washing">Pressure Washing</Link></li>
              <li><Link to="/services/gutter-cleaning">Gutter Cleaning</Link></li>
            </ul>
          </li>
          <li className={location.pathname === '/about-us' ? 'active' : ''}>
            <Link to="/about-us">About Us</Link>
          </li>
          <li className={location.pathname === '/contact-us' ? 'active' : ''}>
            <Link to="/contact-us">Contact Us</Link>
          </li>
          <li className={location.pathname === '/book-now' ? 'active' : ''}>
            <Link to="/book-now">
              <button className='button'>Get a Quote </button>
            </Link>
          </li>
          <li>
            <a href="tel:+13435745415" className="phone-number">
              Call US +1 (343) 574-5415
            </a>
          </li>
        </ul>
        <CiMenuBurger id='menu_icon' onClick={toggleMenu} />
      </div>
    </nav>
  );
}

export default Navbar;