import React from 'react';
import './BookNow.css';
import { useNavigate, Link } from 'react-router-dom'; 
 // Ensure you have this CSS file to style your BookNow page

function BookNow() {
  return (
    <div className='book-now-container'>
      <h1>Get a Quote now</h1>
      <div className='booking-options'>
        <div className='phone-box'>
          <h2>Give us a call</h2>
          <p>Prefer to talk to someone? Get a Quote by phone:</p>
          <p className='phone-numbers'>
            <a href="tel:3435745415">343-574-5415</a>
          </p>
        </div>

        <div className='book-now'>
        <Link to="/quote">
          <h2>Get a Quote Online</h2>
          
          <p>Save time by filling out our online form. You're just a few clicks away from your quote:
          </p>
          <button>Get a Quote now </button>
          </Link>

        </div>
      </div>
    </div>
  );
}

export default BookNow;