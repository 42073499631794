import './App.css';
import Hero from './component/Hero';
import Navbar from './component/Navbar';

import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Pages/Home';
import Services from './Pages/Services';
import AboutUs from './Pages/AboutUs';
import ContactUs from './Pages/ContactUs'
import BookNow from './Pages/BookNow';
import Footer from './component/Footer';
import ChooseUs from './component/ChooseUs';
import FAQ from './component/FAQ';
import Quote from './component/Quote';
function App() {
  return (

  
      <Router>
      <Navbar />

      <ConditionalComponents />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/book-now" element={<BookNow />} />
        <Route path="/quote" element={<Quote/>}/>
      </Routes>


      <Footer/>
    </Router>

  );
}



function ConditionalComponents() {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (
    <>
      {isHomePage && (
        <>
          <Hero />
          
          <ChooseUs/>
          <FAQ/>
        </>
      )}
    </>
  );
}




export default App;
