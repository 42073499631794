import React, { useState, useEffect } from 'react';
import './Hero.css';
import { Link } from 'react-router-dom';

import residentialImage from '../assests/residentail.jpg';
import commercialImage from '../assests/window-clean-825x619.jpg';
import pressureWashingImage from '../assests/Exploring-The-Science-Behind-Pressure-Washing.jpg';
import christmasLightingImage from '../assests/christmas-lights-2.jpg';

const slides = [
  {
    title: 'Residential Window Cleaning',
    description: 'With experience in residential areas, we have already satisfied many clients with our residential work.',
    image: residentialImage,
    link: '/book-now',
  },
  {
    title: 'Commercial Window Cleaning',
    description: 'Providing top-tier window cleaning services for commercial buildings, offices, and storefronts.',
    image: commercialImage,
    link: '/book-now',
  },
  {
    title: 'Pressure Washing',
    description: 'High-quality pressure washing services for driveways, decks, and more.',
    image: pressureWashingImage,
    link: '/book-now',
  },
  {
    title: 'Christmas Lighting',
    description: 'Let us help you light up your home or business for the holidays with professional Christmas lighting services.',
    image: christmasLightingImage,
    link: '/book-now',
  },
];

function Hero() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [autoSlide, setAutoSlide] = useState(true);

  useEffect(() => {
    if (autoSlide) {
      const interval = setInterval(() => {
        handleNext();
      }, 5000); // Change slide every 5 seconds
      return () => clearInterval(interval);
    }
  }, [currentSlide, autoSlide]);

  const handleNext = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  const handleManualSlide = () => {
    setAutoSlide(false); // Stop automatic sliding when user manually changes slides
    setTimeout(() => setAutoSlide(true), 10000); // Resume automatic sliding after 10 seconds
  };

  return (
    <div
      className='Hero container'
      style={{ backgroundImage: `url(${slides[currentSlide].image})` }}
    >
      <div className='hero-text'>
        <h1>{slides[currentSlide].title}</h1>
        <p>{slides[currentSlide].description}</p>
        <Link to={slides[currentSlide].link} className='button'>
          Get A Quote
        </Link>
      </div>
      <div className='arrows'>
        <button className='prev' onClick={() => { handlePrev(); handleManualSlide(); }}>
          &#10094;
        </button>
        <button className='next' onClick={() => { handleNext(); handleManualSlide(); }}>
          &#10095;
        </button>
      </div>
    </div>
  );
}

export default Hero;
